import React, { useEffect, FC, useCallback } from 'react';
import NextLink from 'next/link';
import { RadioButton, LoadingIndicator, Link } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import { ShippingMethod } from 'shared/types/ngcCart';
import { useCart } from 'context';
import { useFormat } from 'helpers/hooks/useFormat';
import { useProjectSettings } from 'hooks/useProjectSettings';
import styles from './shippingMethod.module.scss';
import { CheckoutError } from '../../../index';
import { renderShippingPrice } from '../../../utils';
import CheckoutNotification from '../../checkoutNotification';

export interface ShippingMethodsProps {
  fetchShippingMethods: () => void;
  onChangeHandler: (shippingMethodOption: ShippingMethod) => void;
  shippingMethodsListData: ShippingMethod[];
  isLoading: boolean;
  selectedShippingMethod: ShippingMethod;
  setSelectedShippingMethod: (shippingMethod: ShippingMethod) => void;
  shippingMethodError: CheckoutError;
}

const ShippingMethods: FC<ShippingMethodsProps> = ({
  fetchShippingMethods,
  onChangeHandler,
  shippingMethodsListData,
  isLoading,
  selectedShippingMethod,
  setSelectedShippingMethod,
  shippingMethodError,
}) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatMessageAccount } = useFormat({ name: 'checkout' });
  const { cart } = useCart();
  const { customerSupportEmail } = useProjectSettings();

  useEffect(() => {
    if (Array.isArray(shippingMethodsListData) && shippingMethodsListData.length > 0) {
      findSelectedShippingMethod();
    }
  }, [shippingMethodsListData]);

  const findSelectedShippingMethod = () => {
    if (cart?.shippingInfo?.id !== undefined) {
      setSelectedShippingMethod(cart?.shippingInfo as ShippingMethod);
      onChangeHandler(cart?.shippingInfo);
    } else {
      setSelectedShippingMethod(shippingMethodsListData[0]);
      onChangeHandler(shippingMethodsListData[0]);
    }
  };

  const accountManagerEmail = (
    <Link tag="div" className="nav-link" data-testid="ar__account-manager-email_test-id">
      <NextLink href={`mailto:${customerSupportEmail}`}>
        <span className="underline">{customerSupportEmail}.</span>
      </NextLink>
    </Link>
  );

  const getErrorMessage = useCallback(() => {
    const errorText = formatMessage({
      id: 'shipping.method.error',
      defaultMessage: ' We were unable to load your shipping methods.',
    });

    return (
      <span>
        {errorText}
        {!shippingMethodError.showTryAgain ? (
          <>
            {' '}
            {formatMessage({
              id: 'contact.account.manager',
              defaultMessage: 'Please contact your Account Manager directly, or email ',
            })}
            {accountManagerEmail}
          </>
        ) : null}
      </span>
    );
  }, [shippingMethodError]);

  return (
    <div className={cs(styles.shippingMethod)} data-testid="shipping-method__container_test-id">
      <div className="lll-text-small">
        {formatMessage({ id: 'shipping.method', defaultMessage: 'Shipping method' })}
      </div>
      {isLoading ? (
        <div className={styles.loaderContainer} data-testid="shipping-method__loading-indicator_test-id">
          <LoadingIndicator color="red" />
        </div>
      ) : (
        (shippingMethodError.isError && (
          <CheckoutNotification
            message={getErrorMessage()}
            show={shippingMethodError.isError}
            showTryAgain={shippingMethodError.showTryAgain}
            tryAgainCallback={shippingMethodError.callBack}
          />
        )) || (
          <div className={styles.shippingMethodContent} data-testid="shipping-method__list-container_test-id">
            {shippingMethodsListData &&
              shippingMethodsListData.length > 0 &&
              shippingMethodsListData.map((item, index) => {
                return (
                  <RadioButton
                    data-testid={`shipping-methods__radio-${index}_test-id`}
                    id={`shipping-methods__radio-${index}`}
                    key={index}
                    name="radio"
                    classes={{
                      icon: 'radioDemoIcon',
                      label: 'radioDemoLabel',
                      labelContent: 'flex flex-col gap-4',
                    }}
                    checked={item.id === selectedShippingMethod?.id}
                    onChange={() => onChangeHandler(item)}
                  >
                    <div className="lll-text-body-1 lll-font-weight-medium">{item.name}</div>
                    <div className="lll-text-body-2">
                      <span>{item.description}</span>{' '}
                      <span className="lll-font-weight-medium">
                        (
                        {renderShippingPrice(
                          item?.shippingPrice?.total,
                          formatMessage({ id: 'freeShipping', defaultMessage: 'FREE' }),
                        )}
                        )
                      </span>
                    </div>
                  </RadioButton>
                );
              })}
          </div>
        )
      )}
    </div>
  );
};

export default ShippingMethods;
