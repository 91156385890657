import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';

export const CDP_ANALYTIC_EVENT = {
  FILTER_PILL_CLICKED: (key: string, label: string) => {
    return {
      component: {
        id: 'filter_pill',
        type: COMPONENT_TYPE.BUTTON,
        text: ` Filter pill is clicked/removed`,
      },
      attributes: {
        details: { data: { key, label } },
      },
    };
  },

  SORT_FILTER_APPLIED: (data: { attribute: string; value: 'asc' | 'desc' }) => {
    return {
      component: {
        id: 'sort_filter_applied',
        type: COMPONENT_TYPE.BUTTON,
        text: 'The sorting applied',
      },
      attributes: {
        details: { data },
      },
    };
  },

  FILTER_APPLIED: (type: string, value: string | undefined) => {
    return {
      component: {
        id: 'filter_applied',
        type: COMPONENT_TYPE.BUTTON,
        text: 'The Filter Applied',
      },
      attributes: {
        details: { data: { type, value } },
      },
    };
  },

  PAGINATION_VIEW_MORE_CLICKED: {
    component: {
      id: 'pagination_view_more',
      type: COMPONENT_TYPE.BUTTON,
      text: 'The pagination view more button is clicked',
    },
  },

  CLEAR_FILTERS_CLICKED: {
    component: {
      id: 'clear_filters',
      type: COMPONENT_TYPE.BUTTON,
      text: 'Clear filters is clicked',
    },
  },

  PRODUCT_TILE_CLICKED: (data: any) => {
    return {
      event: {
        details: `Product tile is clicked`,
      },
      attributes: {
        details: data,
      },
    };
  },

  PRODUCT_SWATCH_CLICKED: (data: any) => {
    return {
      component: {
        id: 'product_swatch',
        type: COMPONENT_TYPE.BUTTON,
        text: `Product swatch is clicked`,
      },
      attributes: {
        details: data,
      },
    };
  },

  PRODUCT_VIEWED: (data: any) => {
    return {
      component: {
        id: 'product_viewed',
        type: COMPONENT_TYPE.BUTTON,
        text: `Product viewed`,
      },
      attributes: {
        details: data,
      },
    };
  },

  PRODUCT_CLICKED: (data: any) => {
    return {
      component: {
        id: 'product',
        type: COMPONENT_TYPE.BUTTON,
        text: `Product clicked`,
      },
      attributes: {
        details: data,
      },
    };
  },

  INVENTORY_GRID_INTERACTION: (data: any) => {
    return {
      component: {
        id: 'inventory_grid_interaction',
        type: COMPONENT_TYPE.BUTTON,
        text: `Inventory grid interaction`,
      },
      attributes: {
        details: data,
      },
    };
  },

  ADD_TO_BAG_API_RESPONSE: {
    event: {
      id: 'add_to_bag_api_response',
      attributes: {
        details: 'Add to bag call success response',
      },
    },
  },

  ADD_TO_BAG_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'remove_line_item_api_error',
        errorMessage: 'Error in calling add to bag API',
        errorDetails,
      },
    };
  },
};
