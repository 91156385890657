import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';

export const AUTH = {
  RESET_PASSWORD_BUTTON_CLICKED: {
    component: {
      id: 'reset_password',
      type: COMPONENT_TYPE.BUTTON,
      text: 'Reset password button is clicked',
    },
  },
  LOGIN_BUTTON_CLICKED: {
    component: {
      id: 'login',
      type: COMPONENT_TYPE.BUTTON,
      text: 'Login button is clicked',
    },
  },
  PASSWORD_RESET_REQUESTS: {
    component: {
      id: 'password_reset_requests',
      type: COMPONENT_TYPE.EVENT,
      text: 'Password reset requests',
    },
  },
  ACCOUNT_LOCKOUT: {
    component: {
      id: 'account_lockout',
      type: COMPONENT_TYPE.EVENT,
      text: 'Account lockout',
    },
  },
  LOGIN_SUCCESS: (role: any) => {
    return {
      component: {
        id: 'login_success',
        type: COMPONENT_TYPE.EVENT,
        text: 'Login success',
      },
      attributes: {
        details: { data: role },
      },
    };
  },
  LOGIN_API_RESPONSE: {
    id: 'sign_in_success',
    event: {
      attributes: {
        details: 'success',
      },
    },
  },
  FORGOT_PASSWORD_API_RESPONSE: {
    event: {
      id: 'reset_password_api_success',
      attributes: {
        details: 'Reset password Api call success response',
      },
    },
  },
  SIGN_OUT_API_RESPONSE: {
    event: {
      id: 'logout_api_success',
      attributes: {
        details: 'Logout api call success response',
      },
    },
  },
  LOGIN_API_ERROR: (errorDetails: any) => {
    return {
      error: { errorType: 'login_api_error', errorMessage: 'Error in calling login API', errorDetails },
    };
  },
  LOGOUT_API_ERROR: (errorDetails: any) => {
    return {
      error: { errorType: 'logout_api_error', errorMessage: 'Error in calling logout API', errorDetails },
    };
  },
  FORGOT_PASSWORD_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'forgot_password_api_error',
        errorMessage: 'Error in calling forgot password API',
        errorDetails,
      },
    };
  },
  GET_PASSWORD_RESET_BY_TOKEN_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'get_password_reset_by_token_api_error',
        errorMessage: 'Error in calling get password reset by token API',
        errorDetails,
      },
    };
  },
  GET_PASSWORD_RESET_BY_TOKEN_API_RESPONSE: {
    event: {
      id: 'get_password_reset_by_token_api_success',
      attributes: {
        details: 'Get password reset by token api call success response',
      },
    },
  },

  RESET_PASSWORD_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'reset_password_api_error',
        errorMessage: 'Error in calling reset password api error API',
        errorDetails,
      },
    };
  },

  RESET_PASSWORD_API_RESPONSE: {
    event: {
      id: 'reset_password_api_response',
      attributes: {
        details: 'Reset password api call success response',
      },
    },
  },
};
