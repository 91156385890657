import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';

export const PDP_ANALYTIC_EVENT = {
  COLOR_SWATCH_CLICKED: (product: any) => {
    return {
      event: {
        component: {
          id: 'color_swatch',
          type: COMPONENT_TYPE.BUTTON,
          text: 'Color swatch clicked',
        },
        product,
      },
    };
  },
  PRODUCT_IMAGE_ZOOM_MODAL: (product: any) => {
    return {
      event: {
        component: {
          id: 'product_image_zoom_modal',
          type: COMPONENT_TYPE.PRODUCT_DETAILS,
          text: 'Product image zoom modal',
        },
        product,
      },
    };
  },
  PRODUCT_IMAGE_THUMBNAILS: (product: any) => {
    return {
      event: {
        component: {
          id: 'product_image_thumbnail',
          type: COMPONENT_TYPE.PRODUCT_DETAILS,
          text: 'Product image thumbnails',
        },
        product,
      },
    };
  },
  PRODUCT_EDUCATION_ACCORDIONS_CLICKED: (title: string | undefined) => {
    return {
      component: {
        id: 'product_education_accordions',
        type: COMPONENT_TYPE.BUTTON,
        text: `Product education accordions clicked`,
      },
      attributes: {
        details: { data: title },
      },
    };
  },
  SIZE_GUIDE_LINK_CLICKED: {
    component: {
      id: 'size_guide_link',
      type: COMPONENT_TYPE.BUTTON,
      text: `Size guide link clicked`,
    },
  },
  PRODUCT_INSEAM_CLICKED: (sku: string, currentVariant: any) => {
    return {
      component: {
        id: 'product_inseam',
        type: COMPONENT_TYPE.BUTTON,
        text: `Product inseam is clicked`,
      },
      product: { sku, currentVariant },
    };
  },
  INVENTORY_GRID_INTERACTION: (data: any) => {
    return {
      component: {
        id: 'inventory_grid_interaction',
        type: COMPONENT_TYPE.EVENT,
        text: `Inventory grid interaction`,
      },
      attributes: {
        details: { data },
      },
    };
  },
  ADD_TO_CART_CLICKED: (data: any) => {
    return {
      component: {
        id: 'add_to_cart',
        type: COMPONENT_TYPE.BUTTON,
        text: `add to bag`,
      },
      attributes: {
        details: { data },
      },
    };
  },
};
