import { FC, useEffect, useState, MutableRefObject, useMemo } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Link } from '@lululemon/ecom-pattern-library';
import { Account } from 'shared/types/account';
import { Level3NavItem } from 'shared/types/nav';
import { Category } from 'shared/types/product';
import { useNavContext } from 'context/nav';
import { splitItemsIntoColumns } from 'helpers/arrayHelpers';
import categoryQueryParams from 'helpers/categoryQueryParam';
import { HEADER } from 'helpers/utils/googleAnalyticsEvents';
import { createRefMap } from 'helpers/utils/nav-ref-map';
import { useAnalytics } from 'hooks/useAnalytics';
import { useProductList } from 'ui/cdp/context';
import { useAccount } from 'frontastic/index';
import styles from './main-details.module.scss';

// this is to show max sub-categories per column in nav drop down
const MAX_SUBCATEGORIES_PER_COLUMN = 9;

type Element1 = MutableRefObject<HTMLElement> | undefined;
type Element2 = MutableRefObject<HTMLUnknownElement> | undefined;
export type NavCategory = Omit<
  Level3NavItem,
  'l3SubcategoryLinkDisplayName' | 'l3SubcategoryLinkSlug' | 'level4SubcategoryLinksSection' | 'l3Title'
>;

export const getPath = (account: Account | undefined, limit: number, category: NavCategory): string => {
  const searchParams = new URLSearchParams();
  if (category?.gender) {
    searchParams.append('gender', category?.gender);
  }
  const categoryParams = categoryQueryParams(account, limit, searchParams);

  return `${category.path}?${categoryParams}`;
};
export interface MainDetailsCategoryListProps {
  account: Account | undefined;
  limit: number;
  categoryPath: string;
  categoryName: string;
  gender: string;
  innerRef: any;
  handleCloseDetailedNav: () => void;
}

export const MainDetailsCategoryList = ({
  account,
  limit,
  categoryPath,
  categoryName,
  gender,
  innerRef,
  handleCloseDetailedNav,
}: MainDetailsCategoryListProps) => {
  const router = useRouter();
  const path = getPath(account, limit, { gender, path: categoryPath });
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  const handleOnClick = () => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.MAIN_DETAILS_CATEGORY_LIST_CLICKED(categoryName, categoryPath));
    handleCloseDetailedNav();
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleCloseDetailedNav();
      router.push(path);
      trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.MAIN_DETAILS_CATEGORY_LIST_CLICKED(categoryName, categoryPath));
    }
  };

  return (
    <Link
      tag="div"
      className={styles.mainDetailsCategory}
      variant="arrowBold"
      direction="right"
      onClick={handleOnClick}
      onKeyDown={onKeyDown}
    >
      <div ref={innerRef} tabIndex={-1}>
        <NextLink href={path}>{categoryName}</NextLink>
      </div>
    </Link>
  );
};

export const MainDetailsSubCategoryList: FC<{
  account: Account | undefined;
  limit: number;
  categories: Level3NavItem[];
  setDetailRefs: (index: number, columnIndex: number, extra: number) => any | undefined;
  columnIndex: number;
  extra?: number;
  handleCloseDetailedNav: () => void;
}> = ({ account, limit, categories, setDetailRefs, columnIndex, extra = 0, handleCloseDetailedNav }) => {
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  const handleOnClick = (category: Category) => {
    handleCloseDetailedNav();
    trackEvent(
      EVENT_CATEGORY.COMPONENT_EVENT,
      HEADER.MAIN_DETAILS_SUBCATEGORY_LIST_CLICKED(category.name, category.path),
    );
  };

  return (
    <>
      {categories?.map((category, index) => (
        <li key={category.categoryId}>
          {category.path && (
            <Link
              tag="div"
              className={styles.mainDetailsSubCategory}
              variant="underlineAnimated"
              onClick={() => handleOnClick(category)}
            >
              <NextLink href={getPath(account, limit, category)}>
                <button ref={setDetailRefs(index, columnIndex, extra)} tabIndex={-1}>
                  {category?.l3SubcategoryLinkDisplayName}
                </button>
              </NextLink>
            </Link>
          )}
        </li>
      ))}
    </>
  );
};

const MainDetails = () => {
  const [refIndex, setRefIndex] = useState<number | null>(null);
  const [headerActive, setHeaderActive] = useState(false);
  const { account } = useAccount();
  const { limitStep } = useProductList();

  const { getRef, setRef } = createRefMap();

  const {
    activeL1Index,
    activeL2Index,
    setFeaturesActivated,
    subcategoriesActivated,
    setSubcategoriesActivated,
    handleTabPress,
    handleEscapePress,
    setActiveL2Index,
    handleCloseDetailedNav,
    activeCategory: category,
  } = useNavContext();

  const maxSubPerColumn = MAX_SUBCATEGORIES_PER_COLUMN;

  useEffect(() => {
    if (!subcategoriesActivated) {
      setRefIndex(null);
      setHeaderActive(false);
    }
  }, [subcategoriesActivated]);

  useEffect(() => {
    if (subcategoriesActivated) {
      if (refIndex === -1) {
        setHeaderActive(true);
      } else if (refIndex !== null) {
        const columnIndex2 = hasFeatures ? activeL2Index - 1 : activeL2Index;
        getRef(`details-${activeL1Index}-${columnIndex2}-${refIndex}`)?.current.focus();
      }
    }
  }, [refIndex]);

  useEffect(() => {
    if (subcategoriesActivated) {
      const columnIndex2 = hasFeatures ? activeL2Index - 1 : activeL2Index;
      getRef(`details-header-${activeL1Index}-${columnIndex2}`)?.current.focus();
    }
  }, [subcategoriesActivated, activeL2Index]);

  useEffect(() => {
    const columnIndex2 = hasFeatures ? activeL2Index - 1 : activeL2Index;
    if (headerActive && subcategoriesActivated) {
      getRef(`details-header-${activeL1Index}-${columnIndex2}`).current.focus();
      setRefIndex(null);
      setHeaderActive(false);
    }
  }, [headerActive]);

  const subCategories = category?.displaySubcategories || [];
  const hasFeatures = category?.hasFeatures;
  const hasAccessoriesOrBags = category?.hasAccessoriesOrBags;
  const totalMainColumnCount = category?.totalMainColumnCount || 1;
  const categoryPath = category?.path || '';
  const categoryName = category?.displayl2SubcategoryName || '';
  const gender = category?.gender || '';

  const data = useMemo(() => {
    return splitItemsIntoColumns(subCategories, maxSubPerColumn);
  }, [subCategories, maxSubPerColumn]);

  const columns = [
    {
      path: categoryPath,
      name: categoryName,
      gender: '',
      cats: data,
    },
  ];

  if (hasAccessoriesOrBags) {
    const { displayAccessoriesOrBags = [], column2ndName, column2ndCategoryPath } = category;

    columns.push({
      path: column2ndCategoryPath,
      name: column2ndName,
      gender,
      cats: [displayAccessoriesOrBags],
    });
  }

  const lengthOfCurrentColumn = useMemo(() => {
    let length = 0;

    if ((hasFeatures && activeL2Index === 1) || (!hasFeatures && activeL2Index === 0)) length = subCategories?.length;
    else if (hasAccessoriesOrBags) length = columns[1].cats[0].length;

    return length;
  }, [hasFeatures, activeL2Index]);

  const onKeyDown = (evt: any) => {
    if (!subcategoriesActivated) return;

    switch (evt.key) {
      case 'ArrowDown': {
        evt.preventDefault();
        if (refIndex === null) {
          setRefIndex(0);
        } else if (refIndex + 1 === lengthOfCurrentColumn) {
          setHeaderActive(true);
        } else {
          setRefIndex(refIndex + 1);
        }
        break;
      }
      case 'ArrowUp': {
        evt.preventDefault();
        if (refIndex === null) {
          setRefIndex(lengthOfCurrentColumn - 1);
        } else {
          setRefIndex(refIndex - 1);
        }
        break;
      }
      case 'ArrowRight': {
        evt.preventDefault();
        setRefIndex(null);
        if (!evt.repeat) {
          const hasNextItem = activeL2Index < totalMainColumnCount - 1;
          if (!hasNextItem) {
            setActiveL2Index(0);
            if (hasFeatures) {
              setFeaturesActivated(true);
              setSubcategoriesActivated(false);
            }
            if (!hasFeatures && !hasAccessoriesOrBags) {
              // this check is made, when only subcategories there. then if focus is any of item in list,
              // then if pressing right or left arrow, we made the header active, so that header subcategory can be focussed.
              setHeaderActive(true);
            }
          } else {
            setActiveL2Index(activeL2Index + 1);
          }
        }
        break;
      }
      case 'ArrowLeft': {
        evt.preventDefault();
        setRefIndex(null);
        if (!evt.repeat) {
          const hasPrevItem = activeL2Index > 0;
          if (!hasPrevItem) {
            setActiveL2Index(totalMainColumnCount - 1);
            if (!hasFeatures && !hasAccessoriesOrBags) {
              setHeaderActive(true);
            }
          } else {
            if (hasFeatures && activeL2Index === 1) {
              setFeaturesActivated(true);
              setSubcategoriesActivated(false);
            }
            setActiveL2Index(activeL2Index - 1);
          }
        }
        break;
      }
      case 'Tab': {
        evt.preventDefault();
        handleTabPress(evt.shiftKey);
        setRefIndex(null);
        break;
      }
      case 'Escape': {
        evt.preventDefault();
        handleEscapePress();
        setRefIndex(null);
        break;
      }
      default:
        break;
    }
  };

  const setDetailRefs = (index: number, columnIndex: number, extra: number): Element1 => {
    return setRef(`details-${activeL1Index}-${columnIndex}-${index + extra}`);
  };

  const setHeaderRef = (columnIndex: number): Element2 => {
    return setRef(`details-header-${activeL1Index}-${columnIndex}`);
  };

  return (
    <div
      className={styles.mainDetailsWrapper}
      data-testid="main-detail__wrapper_test-id"
      onKeyDown={onKeyDown}
      tabIndex={-1}
    >
      {columns.map((level2Group, columnIndex) => {
        return level2Group.cats.map((subCat: any, i) => (
          <ul className={styles.mainDetailsList} key={i}>
            {i === 0 ? (
              <MainDetailsCategoryList
                account={account}
                limit={limitStep}
                categoryPath={level2Group.path}
                categoryName={level2Group.name}
                gender={level2Group.gender}
                innerRef={setHeaderRef(columnIndex)}
                handleCloseDetailedNav={handleCloseDetailedNav}
              />
            ) : (
              '\u0000'
            )}
            <MainDetailsSubCategoryList
              account={account}
              limit={limitStep}
              categories={subCat}
              setDetailRefs={setDetailRefs}
              columnIndex={columnIndex}
              handleCloseDetailedNav={handleCloseDetailedNav}
              extra={i > 0 ? i * level2Group.cats[i - 1].length : 0}
            />
          </ul>
        ));
      })}
    </div>
  );
};

export default MainDetails;
