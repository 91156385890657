import React, { useState, useEffect, FC, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Field, Modal, Heading, LoadingIndicator, Checkbox, Select, Icon } from '@lululemon/ecom-pattern-library';
import useResolveCCImage from 'hooks/useResolveCCImage';
import { useFormat } from 'helpers/hooks/useFormat';
import useValidate from 'helpers/hooks/useValidate';
import { formatAddressDisplay } from 'helpers/utils/formatAddress';
import { ACCOUNT } from 'helpers/utils/googleAnalyticsEvents';
import { maskValue } from 'helpers/utils/maskCreditcardValue';
import { useAnalytics } from 'hooks/useAnalytics';
import { useBusinessUnit, useCybersource } from 'frontastic/hooks';
import {
  ModalCyberSourceRequestMapper,
  CreditCardType,
  RequestCyberSourceDataMapper,
  CreditCardLabelType,
} from 'frontastic/hooks/useCybersource/types';
import ConfirmationDeleteModal from './confirmationDeleteModal';
import styles from './PaymentEdit.module.scss';
import WarningMessageModal from './warningMessageModal';
import SaveOrCancel from '../../../components/save-or-cancel';
export interface EditModalProps {
  modalProps: ModalCyberSourceRequestMapper;
  setModalProps: Dispatch<SetStateAction<ModalCyberSourceRequestMapper | undefined>>;
  creditCardsData: CreditCardType[];
  onResponseHandler: (isError: boolean, isRemove: boolean) => void;
}
export interface TransformedBillingInfo {
  label?: string;
  value?: string;
}

const PaymentEdit: FC<EditModalProps> = ({ modalProps, setModalProps, creditCardsData, onResponseHandler }) => {
  const { formatMessage: formatPaymentMessage } = useFormat({ name: 'payment' });
  const { formatMessage } = useFormat({ name: 'common' });
  const { resolveCCImage } = useResolveCCImage();
  const [isLoading, setLoading] = useState(false);
  const { billingAddresses, businessUnit } = useBusinessUnit();
  const [businessUnitData, setBusinessUnitData] = useState<TransformedBillingInfo[]>([]);
  const { validateExpiryDate } = useValidate();

  const [formData, setFormData] = useState<CreditCardType>(modalProps.creditCardDetails);
  const [isErrored, setIsErrored] = useState({
    expirydate: false,
    nameOnCard: false,
  } as any);
  const [displayStatus, setDisplayStatus] = useState({
    expirydate: 'warning',
    nameOnCard: 'warning',
  });
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();
  const { updatePaymentDetail } = useCybersource();

  useEffect(() => {
    if (billingAddresses.length >= 1) {
      const transformedBillingAddress: TransformedBillingInfo[] = billingAddresses.map((address) => ({
        label: formatAddressDisplay(address, false),
        value: address.addressId,
      }));
      setBusinessUnitData(transformedBillingAddress);
    }
  }, [billingAddresses]);

  useEffect(() => {
    setFormData(modalProps.creditCardDetails);
    setIsErrored({
      expirydate: false,
      nameOnCard: false,
    } as any);
    setDisplayStatus({
      expirydate: 'warning',
      nameOnCard: 'warning,',
    });
  }, [modalProps]);

  const closeModal = (modalProps: ModalCyberSourceRequestMapper) => {
    closeEditModal(modalProps);
  };

  const updateCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, ACCOUNT.SELECT_DEFAULT_CREDIT_CARD_CLICKED);
    const { checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      isCardPrimary: checked ? checked : false,
    }));
  };

  function handleChange(e: ChangeEvent<any>) {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  const UpdateCreditCard = (creditCardDetail: CreditCardType) => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, ACCOUNT.REMOVE_CREDIT_CARD_CLICKED);
    const validExpiryData: boolean = validateExpiryDate(formData.expires as string);
    const validNameOnCard = formData.nameOnCard.trim() !== '';
    const validForm = validExpiryData && validNameOnCard;
    if (validForm) {
      const updatedEditModalProps: ModalCyberSourceRequestMapper = {
        ...modalProps,
        creditCardDetails: formData,
      };
      updateCreditCardWrapper(updatedEditModalProps, false);
    } else {
      if (!validExpiryData)
        setIsErrored({
          ...isErrored,
          expirydate: !validExpiryData,
        });
      setDisplayStatus({
        ...displayStatus,
        expirydate: 'error',
      });

      if (!validNameOnCard) {
        setIsErrored({
          ...isErrored,
          nameOnCard: !validNameOnCard,
        });
        setDisplayStatus({
          ...displayStatus,
          nameOnCard: 'error',
        });
      }
    }
  };

  const closeConfirmationDeleteModal = (modalProps: ModalCyberSourceRequestMapper) => {
    setModalProps({
      ...modalProps,
      isConfirmationModalOpen: false,
    });
  };

  const closeWarningMessageModal = (modalProps: ModalCyberSourceRequestMapper) => {
    setModalProps({
      ...modalProps,
      isWarningMessageModalOpen: false,
    });
  };

  const handleWarningMessageModal = (modalProps: ModalCyberSourceRequestMapper) => {
    setModalProps({
      ...modalProps,
      isModalOpen: false,
      isConfirmationModalOpen: false,
      isWarningMessageModalOpen: true,
    });
  };

  const openConfirmationModel = (modalProps: ModalCyberSourceRequestMapper) => {
    setModalProps({
      ...modalProps,
      isModalOpen: false,
      isConfirmationModalOpen: true,
    });
  };

  const updatePaymentDetailMethod = async (updateBillingInfo: CreditCardType[], isRemove: boolean) => {
    const businessUnitKey: string = businessUnit.key;
    const requestData: RequestCyberSourceDataMapper = { key: businessUnitKey, value: updateBillingInfo };
    try {
      const response = await updatePaymentDetail(requestData);
      onResponseHandler(response.isError, isRemove);
    } catch (error) {
      onResponseHandler(true, isRemove);
    }
  };

  function closeEditModal(modalProps: ModalCyberSourceRequestMapper) {
    setModalProps({
      ...modalProps,
      isModalOpen: false,
      isConfirmationModalOpen: false,
      listCardsLength: creditCardsData.length,
    });
  }

  function updateCreditCardWrapper(modalProps: ModalCyberSourceRequestMapper, isRemove: boolean) {
    if (isRemove) {
      const updatedCreditCardsData: CreditCardType[] = creditCardsData.filter(
        (element) => element.partnerIdUUID !== modalProps?.creditCardDetails.partnerIdUUID,
      );
      updatePaymentDetailMethod(updatedCreditCardsData, true);
      closeEditModal(modalProps);
    } else {
      const updatedCreditCardsData: CreditCardType[] = creditCardsData.map((element) => {
        const updatedCreditCard = modalProps?.creditCardDetails;
        if (element.partnerIdUUID === updatedCreditCard.partnerIdUUID) {
          return modalProps?.creditCardDetails;
        } else {
          if (element.isCardPrimary && updatedCreditCard.isCardPrimary) {
            element.isCardPrimary = false;
          }
          return element;
        }
      });

      updatePaymentDetailMethod(updatedCreditCardsData, false);
      closeEditModal(modalProps);
    }
  }

  return (
    <>
      <Modal
        aria-label="Edit Credit Card"
        onRequestClose={() => {
          closeModal(modalProps);
        }}
        visible={modalProps?.isModalOpen}
        classes={{
          modal: 'min-w-[27rem]',
        }}
        data-testid="edit-credit-card_modal_test-id"
      >
        <div className="w-min-300">
          <Heading className="lll-text-xsmall mb-16" tag="h1" data-testid="edit-credit-card__heading_test-id">
            {formatPaymentMessage({
              id: 'edit.credit_card',
              defaultMessage: 'Edit your credit card',
            })}
          </Heading>

          {isLoading && (
            <div className={styles.editCreditCardLoader}>
              <LoadingIndicator color="gray" className="self-center" />
            </div>
          )}
          {!isLoading && (
            <>
              <div className="my-16 flex flex-col">
                <div className="mb-8 flex">
                  <div className="mr-5 flex items-center">
                    <Icon
                      className="h-fit w-26"
                      title={formData.cardType}
                      content={resolveCCImage(formData.maskedPan)}
                      data-testid={'edit-credit_card_cctype-icon_test-id'}
                    />
                  </div>
                  <div className="lll-text-body-2 ml-5 mr-16">
                    {formatPaymentMessage({
                      id: 'maskedCC.displayMsg',
                      defaultMessage: `${
                        CreditCardLabelType[modalProps?.creditCardDetails.cardType as keyof typeof CreditCardLabelType]
                      }
                      ending in ${maskValue(modalProps?.creditCardDetails.maskedPan)}`,
                    })}
                  </div>
                </div>
              </div>
              <div className="my-16 flex flex-col">
                <Field
                  type="text"
                  required
                  data-testid={'edit-credit_card_expiry-input_test-id'}
                  label={formatMessage({ id: 'expiry', defaultMessage: 'Expiry (MM/YY)' })}
                  name="expires"
                  id="edit-credit_card_expiry-input"
                  disabled={false}
                  value={formData.expires}
                  onChange={handleChange}
                  status={displayStatus['expirydate']}
                  hint={
                    isErrored['expirydate']
                      ? formatMessage({
                          id: 'edit.invalid.expires',
                          defaultMessage: 'Please enter a valid expiry date.',
                        })
                      : ''
                  }
                >
                  {formatMessage({ id: 'expiry', defaultMessage: 'Expiry (MM/YY)' })}
                </Field>
              </div>

              <div className="my-16 flex flex-col">
                <Field
                  type="text"
                  required
                  data-testid={'edit_credit-card-name__input_test-id'}
                  label={formatMessage({ id: 'name_on_card', defaultMessage: 'Name on card' })}
                  name="nameOnCard"
                  id="edit_credit-card-name__input"
                  disabled={false}
                  value={formData.nameOnCard}
                  status={displayStatus['nameOnCard']}
                  hint={isErrored['nameOnCard'] ? 'Invalid Name' : ''}
                  onChange={handleChange}
                >
                  {formatMessage({ id: 'name_on_card', defaultMessage: 'Name on card' })}
                </Field>
              </div>

              <div className="my-16 flex flex-col">
                <Heading className="lll-text-xsmall" tag="h3">
                  {formatPaymentMessage({ id: 'billing.address', defaultMessage: 'Billing address' })}
                </Heading>
                <Select
                  required={false}
                  id="edit_credit-card-billing-address__select"
                  name="cardBillingAddressId"
                  placeholder=" "
                  options={businessUnitData}
                  value={formData.cardBillingAddressId}
                  onChange={handleChange}
                  data-testid={'edit_credit_card__billing_address_select_test-id'}
                >
                  {formatPaymentMessage({
                    id: 'select.address.credit_card',
                    defaultMessage: 'Select an existing address on file',
                  })}
                </Select>
              </div>

              <div className="my-16 flex flex-col">
                <Checkbox
                  required={false}
                  id={'edit_credit_card__defaul-checkbox'}
                  name="isCardPrimary"
                  defaultChecked={formData.isCardPrimary}
                  checked={formData.isCardPrimary}
                  onChange={updateCheckBoxChange}
                  data-testid={'edit_credit_card__default-checkbox_test-id'}
                >
                  {formatMessage({ id: 'set.default.credit_card', defaultMessage: 'Set as default credit card' })}
                </Checkbox>
              </div>
            </>
          )}
          <div className="mt-16 flex items-center justify-between">
            <SaveOrCancel
              onCancel={() => {
                if (modalProps?.listCardsLength == 1) {
                  handleWarningMessageModal(modalProps);
                } else {
                  openConfirmationModel(modalProps);
                }
              }}
              onSave={() => {
                UpdateCreditCard(formData);
              }}
              variant="save"
              className="flex-col"
              saveButtonText={formatPaymentMessage({ id: 'save.credit_card', defaultMessage: 'SAVE CREDIT CARD' })}
              displayCancelLink={true}
              displayCancelLinkText={formatPaymentMessage({
                id: 'remove.credit_card',
                defaultMessage: 'Remove Card',
              })}
              secondaryTestId="edit-credit-card__remove-link_test-id"
              testId="edit-credit-card__save-button_test-id"
            />
          </div>
        </div>
      </Modal>

      <ConfirmationDeleteModal
        modalProps={modalProps}
        closeConfirmationDeleteModal={closeConfirmationDeleteModal}
        updateCreditCardWrapper={updateCreditCardWrapper}
      />
      <WarningMessageModal modalProps={modalProps} closeWarningMessageModal={closeWarningMessageModal} />
    </>
  );
};

export default PaymentEdit;
