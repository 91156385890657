import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { searchIcon, closeIcon, Icon } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import { useNavContext } from 'context/nav';
import categoryQueryParams from 'helpers/categoryQueryParam';
import { useFormat } from 'helpers/hooks/useFormat';
import { HEADER } from 'helpers/utils/googleAnalyticsEvents';
import { useAnalytics } from 'hooks/useAnalytics';
import { useProductList } from 'ui/cdp/context';
import { useAccount } from 'frontastic/index';
import styles from './search.module.scss';

const Search: React.FC = () => {
  const [value, setValue] = useState('');
  const router = useRouter();
  const { account } = useAccount();
  const { limitStep } = useProductList();
  const { formatMessage } = useFormat({ name: 'common' });
  const { handleCloseDetailedNav, isSearchFocused, setSearchFocused, setAccountMenuVisible } = useNavContext();
  const { query } = router;

  const inputRef = useRef<HTMLInputElement | null>(null);
  const timeOutId = useRef<number | ReturnType<typeof setTimeout>>();
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();
  useEffect(() => {
    if (isSearchFocused) {
      inputRef.current?.focus();
    }
  }, [isSearchFocused]);

  useEffect(() => {
    const activeSearch = query?.q?.toString() ?? '';
    setValue(activeSearch);
  }, [query, inputRef]);

  const handleInputChange = useCallback((evt) => {
    setValue(evt.target.value);
  }, []);

  const handleFocus = useCallback(() => {
    handleCloseDetailedNav();
    setAccountMenuVisible(false);
    trackEvent(EVENT_CATEGORY.SEARCH, HEADER.SEARCH_INTERACTION_INITIATE);
    setSearchFocused(true);
  }, []);

  const handleClear = useCallback(() => {
    setValue('');
    setSearchFocused(false);
  }, []);

  const handleMaskClick = useCallback(() => {
    try {
      setValue('');
      setSearchFocused(false);
    } catch (e) {}
  }, []);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (!value) return;

      const queryParams = new URLSearchParams();
      queryParams.append('q', value);
      //category link with all query params
      const categoryParams = categoryQueryParams(account, limitStep, queryParams);
      router.push(`/search?${categoryParams}`);
      trackEvent(EVENT_CATEGORY.SEARCH, HEADER.SEARCH_PRODUCT(value));
      // To have enough time for form to grab and submit the value before clearing
      setTimeout(() => {
        setSearchFocused(false);
      });
      inputRef.current?.blur();
    },
    [value, router],
  );

  const handleOnBlur = useCallback(() => {
    // We close the results popover on the next tick by using setTimeout.
    // This is necessary because we first check if another child of the element
    // has received focus as the blur event fires prior to the new focus event.
    timeOutId.current = setTimeout(() => {
      setSearchFocused(false);
    });
  }, []);

  const handleKeyDown = useCallback((evt) => {
    // For accessibity, if the user presses shift + tab, the popover should close
    if (evt.shiftKey && evt.key === 'Tab') {
      setSearchFocused(false);
    }
  }, []);

  const onClickClose = () => {
    try {
      handleClear();
      setSearchFocused(false);
    } catch (e) {}
  };

  const isOpen = isSearchFocused;
  return (
    <div
      className={cs(styles.searchBox, {
        [styles.isOpen]: isOpen,
        [styles.fullScreen]: false,
      })}
      role="none"
      onKeyDown={handleKeyDown}
      onBlur={handleOnBlur}
    >
      {/* mask */}
      <div
        className={cs(styles.mask, styles.delay, {
          [styles.open]: isOpen,
        })}
        onClick={handleMaskClick}
        data-testid="nav-search__mask_test-id"
      />
      {/* form */}
      {/* enclose form in div, fix for safari */}
      <div className={styles.formWrapper}>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          className={cs(styles.form, {
            [styles.fade]: false,
          })}
          data-testid="nav-search__form_test-id"
        >
          {/* clear icon */}
          <Icon
            content={closeIcon}
            className={cs({
              [styles.show]: !!value.length,
              [styles.closeIcon]: true,
            })}
            onClick={onClickClose}
            title="Close"
          />
          {/* search icon */}
          <Icon
            content={searchIcon}
            onClick={handleSubmit}
            className={cs(styles.searchIcon)}
            title={formatMessage({ id: 'search', defaultMessage: 'Search' })}
          />
          {/* search input */}
          <input
            onFocus={handleFocus}
            autoComplete="off"
            placeholder={formatMessage({ id: 'search.placeholder', defaultMessage: 'Search' })}
            className={cs(styles.input, 'lll-text-body-2')}
            name="search"
            type="search"
            value={value}
            onChange={handleInputChange}
            ref={inputRef}
            data-testid={`nav-search__input_test-id`}
          />
        </form>
      </div>
    </div>
  );
};

export default Search;
