import React, { useState, FC, useEffect, useCallback, useMemo } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Heading, AccordionStatelessV2, AccordionItemV2, Link } from '@lululemon/ecom-pattern-library';
import cx from 'classnames';
import { Address } from 'shared/types/account';
import { ExpandedAccordionPanels } from 'shared/types/ecom-pattern-library';
import { NgcErrorType } from 'shared/types/error';
import { OverlayLoader } from 'components/overlayLoader';
import { useCart, PaymentMethodPayload } from 'context/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { parseNGCError } from 'helpers/ngc';
import { CHECKOUT } from 'helpers/utils/googleAnalyticsEvents';
import { isEmpty } from 'helpers/utils/isEmpty';
import { NgcErrorCodes } from 'helpers/utils/ngcResponseUtil';
import { useAnalytics } from 'hooks/useAnalytics';
import { useProjectSettings } from 'hooks/useProjectSettings';
import { useBusinessUnit, useCybersource } from 'frontastic';
import { RequestCyberSourceDataMapper, CreditCardType } from 'frontastic/hooks/useCybersource/types';
import ARPayment from './ARPayment';
import CCPayment from './CCPayment';
import styles from './paymentMethods.module.scss';
import { CheckoutFieldValidityType, CheckoutNotificationPayloadType } from '../../index';
import CheckoutNotification from '../checkoutNotification';

export enum PaymentType {
  creditCard = 'creditCard',
  AR = 'AR',
}

type CheckoutPaymentNotifications =
  | 'creditCardError'
  | 'arError'
  | 'paymentMethodError'
  | 'fetchCreditCardsError'
  | 'creditCardModalNotification';

export type CheckoutPaymentNotificationType = {
  [key in CheckoutPaymentNotifications]?: CheckoutNotificationPayloadType;
};

interface PaymentMethodsProps {
  checkoutFieldValidity: CheckoutFieldValidityType;
  showErrorOnCTAClick: boolean;
  setCheckoutFieldValidity: (updatedValidity: CheckoutFieldValidityType) => void;
  setShowErrorOnCTAClick: (value: boolean) => void;
}

const PaymentMethods: FC<PaymentMethodsProps> = ({
  checkoutFieldValidity,
  showErrorOnCTAClick,
  setCheckoutFieldValidity,
  setShowErrorOnCTAClick,
}) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'checkout' });
  const [isFetchingCreditCards, setIsFetchingCreditCards] = useState(false);
  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = useState(false);

  const { formatMessage: formatMessageAccount } = useFormat({ name: 'account' });

  const [creditCardsData, setCreditCardsData] = useState<CreditCardType[]>([]);
  const [fetchedCreditCards, setFetchedCreditCards] = useState(false);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState<Address>();
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();
  const { customerSupportEmail } = useProjectSettings();

  const AR_PANEL_INDEX = 0;
  const CC_PANEL_INDEX = 1;
  const [expandedAccordionPanels, setExpandedAccordionPanels] = useState<ExpandedAccordionPanels>({
    [AR_PANEL_INDEX]: false,
    [CC_PANEL_INDEX]: true,
  });

  const initialPaymentNotifications = {
    creditCardError: null,
    arError: null,
    fetchCreditCardsError: null,
    addCreditCardModalNotification: null,
    editCreditCardModalNotification: null,
  };
  const [notifications, setNotifications] = useState<CheckoutPaymentNotificationType>(initialPaymentNotifications);

  const { cart, addPaymentMethod, updatePaymentMethod } = useCart();
  const { getPaymentDetails } = useCybersource();
  const {
    businessUnit,
    defaultBillingAddress,
    billingAddresses,
    showAccountsReceivable,
    showCreditCards,
    showCreditCardsAndAR,
  } = useBusinessUnit();

  const businessUnitFetched = !isEmpty(businessUnit);

  const paymentMethodInCart = cart?.payments?.paymentMethod;
  const noPaymentOptionAvailable = !showCreditCards && !showAccountsReceivable;

  const defaultCreditCard = useMemo(
    () =>
      creditCardsData.length === 1
        ? creditCardsData[0]
        : creditCardsData?.find((creditCard: CreditCardType) => creditCard.isCardPrimary),
    [creditCardsData],
  );

  const setAddAndUpdateErrors = useCallback(
    (selectedPaymentMethod: string, errorCallBack?: () => void) => {
      const errorType = selectedPaymentMethod === PaymentType.creditCard ? 'creditCardError' : 'arError';

      const errorPayload = {
        message: formatMessage({
          id: 'save.payment.error',
          defaultMessage: 'We were unable to save your payment method.',
        }),
        tryAgainCallback: errorCallBack,
        showTryAgain: true,
      };

      if (!paymentMethodInCart) {
        setCheckoutFieldValidity({
          paymentMethodAddedInCart: false,
        });
      }
      setNotifications((prevNotifications) => ({ ...prevNotifications, [errorType]: errorPayload }));
    },
    [formatMessage, paymentMethodInCart],
  );

  const updatePaymentMethodHandler = useCallback(
    async (payload: PaymentMethodPayload) => {
      if (isUpdatingPaymentMethod) {
        return;
      }
      const paymentAction = paymentMethodInCart ? updatePaymentMethod : addPaymentMethod;
      setNotifications((prevNotifications) => ({
        ...prevNotifications,
        arError: null,
        creditCardError: null,
      }));
      setIsUpdatingPaymentMethod(true);
      try {
        await paymentAction(payload);
        setCheckoutFieldValidity({
          paymentMethodAddedInCart: true,
          isCreditCardValid: true,
        });
      } catch (error) {
        if (error) {
          const parsedError: NgcErrorType = parseNGCError(error);
          if (parsedError?.code === NgcErrorCodes.CART_ALREADY_ORDERED) {
            router.push('/something?isCartAlreadyOrdered=true');
          }
        }
        setAddAndUpdateErrors(payload.paymentMethod, () => updatePaymentMethodHandler(payload));
      } finally {
        setShowErrorOnCTAClick(false);
        setIsUpdatingPaymentMethod(false);
      }
    },
    [paymentMethodInCart, isUpdatingPaymentMethod],
  );

  const fetchCreditCards = useCallback(async () => {
    const businessUnitKey: string = businessUnit?.key;
    const requestData: RequestCyberSourceDataMapper = { key: businessUnitKey, value: [] };

    setIsFetchingCreditCards(true);
    const errorPayload = {
      message: formatMessage({
        id: 'fetch.cards.error',
        defaultMessage: 'We were unable to load your saved credit cards.',
      }),
      tryAgainCallback: fetchCreditCards,
      showTryAgain: true,
    };

    try {
      const response = await getPaymentDetails(requestData);
      if (!response.isError) {
        const cards = response.data?.value || [];
        setCreditCardsData(cards);
      } else {
        setNotifications((prevNotifications) => ({ ...prevNotifications, fetchCreditCardsError: errorPayload }));
      }
    } catch (error) {
      setNotifications((prevNotifications) => ({ ...prevNotifications, fetchCreditCardsError: errorPayload }));
    } finally {
      setFetchedCreditCards(true);
      setIsFetchingCreditCards(false);
    }
  }, [businessUnit?.key]);

  const accountManagerEmail = (
    <Link tag="div" className="nav-link" data-testid="payment-methods__account-manager-email_test-id">
      <NextLink href={`mailto:${customerSupportEmail}`}>
        <span className="underline">{customerSupportEmail}</span>
      </NextLink>
    </Link>
  );

  const validateCreditCardInCart = useCallback(
    (selectedCreditCard) => {
      if (paymentMethodInCart === PaymentType.creditCard) {
        /* If CC is present and CC is not expired */
        const isCreditCardValid = Boolean(selectedCreditCard) && !selectedCreditCard?.isExpired;

        setCheckoutFieldValidity({
          isCreditCardValid,
          paymentMethodAddedInCart: true,
        });
      }

      if (paymentMethodInCart === PaymentType.AR) {
        setCheckoutFieldValidity({
          paymentMethodAddedInCart: true,
        });
      }
    },
    [cart?.payments?.partnerUUID, creditCardsData, paymentMethodInCart],
  );

  useEffect(() => {
    const addressId = cart?.payments?.billingAddress?.billingAddressId;
    const defaultAddress = defaultBillingAddress || billingAddresses[0];
    if (addressId) {
      const preSelectedAddress = billingAddresses.find((address) => address.addressId === addressId);
      setSelectedBillingAddress(preSelectedAddress || defaultAddress);
    } else {
      setSelectedBillingAddress(defaultAddress);
    }
  }, [defaultBillingAddress, cart, billingAddresses]);

  useEffect(() => {
    if (businessUnitFetched && !isEmpty(cart)) {
      /*if CC to be fetched and shown OR Only AR to be shown */
      if ((showCreditCards && fetchedCreditCards) || showAccountsReceivable) {
        // removed &&!showCreditCards
        if (paymentMethodInCart) {
          /* For the case when paymentMethod is already added in cart */
          const isARSelected = paymentMethodInCart === PaymentType.AR;
          setExpandedAccordionPanels({
            [AR_PANEL_INDEX]: isARSelected,
            [CC_PANEL_INDEX]: !isARSelected,
          });
          setIsUpdatingPaymentMethod(false);

          /**
           * check the validity on the selected payment method and update using the function
           * setCheckoutFieldValidity, if the field value is invalid the error would be shown
           * on CTA click
           */
          const selectedCreditCard = creditCardsData.find(
            (creditCard: CreditCardType) => creditCard.partnerIdUUID === cart?.payments?.partnerUUID,
          );
          validateCreditCardInCart(selectedCreditCard);

          const isPaymentAmoutUpdated = cart?.payments?.amount !== cart?.cartTotal.total;
          if (isPaymentAmoutUpdated) {
            if (paymentMethodInCart === PaymentType.creditCard && selectedCreditCard) {
              updatePaymentMethodHandler({
                paymentMethod: PaymentType.creditCard,
                partnerUUID: selectedCreditCard.partnerIdUUID,
                billingAddressId: selectedCreditCard.cardBillingAddressId,
              });
            } else if (paymentMethodInCart === PaymentType.AR && selectedBillingAddress) {
              updatePaymentMethodHandler({
                paymentMethod: PaymentType.AR,
                billingAddressId: selectedBillingAddress?.addressId,
              });
            }
          }
        } else {
          /* For the case when paymentMethod is not added in cart */
          if (showCreditCards) {
            if (defaultCreditCard) {
              updatePaymentMethodHandler({
                paymentMethod: PaymentType.creditCard,
                partnerUUID: defaultCreditCard.partnerIdUUID,
                billingAddressId: defaultCreditCard.cardBillingAddressId,
              });
            } else {
              setIsUpdatingPaymentMethod(false);
            }
          } else if (showAccountsReceivable && selectedBillingAddress) {
            updatePaymentMethodHandler({
              paymentMethod: PaymentType.AR,
              billingAddressId: selectedBillingAddress?.addressId,
            });
          }
        }
      }
    }
  }, [
    paymentMethodInCart,
    fetchedCreditCards,
    showAccountsReceivable,
    showCreditCards,
    defaultCreditCard,
    selectedBillingAddress,
  ]);

  useEffect(() => {
    if (showErrorOnCTAClick && !checkoutFieldValidity.isCreditCardValid) {
      setNotifications((prevNotifications) => ({
        ...prevNotifications,
        creditCardError: {
          message: formatMessage({
            id: 'select.credit.card',
            defaultMessage: 'Please select a credit card to continue.',
          }),
        },
      }));
    }
  }, [checkoutFieldValidity.isCreditCardValid, showErrorOnCTAClick]);

  useEffect(() => {
    if (businessUnitFetched) {
      if (showCreditCards && !fetchedCreditCards) {
        fetchCreditCards();
      }
      if (noPaymentOptionAvailable) {
        setIsFetchingCreditCards(false);
        setIsUpdatingPaymentMethod(false);
      }
    }
  }, [businessUnitFetched, fetchedCreditCards, noPaymentOptionAvailable, showCreditCards, showCreditCardsAndAR]);

  const accordionItemClickHandler = () => {
    const isCCPanelActive = !expandedAccordionPanels[CC_PANEL_INDEX];
    setExpandedAccordionPanels({
      [AR_PANEL_INDEX]: !isCCPanelActive,
      [CC_PANEL_INDEX]: isCCPanelActive,
    });

    if (isCCPanelActive && defaultCreditCard) {
      updatePaymentMethodHandler({
        paymentMethod: PaymentType.creditCard,
        partnerUUID: defaultCreditCard?.partnerIdUUID,
        billingAddressId: defaultCreditCard?.cardBillingAddressId,
      });
    } else {
      updatePaymentMethodHandler({
        paymentMethod: PaymentType.AR,
        billingAddressId: selectedBillingAddress?.addressId,
      });
    }
  };

  const notificationHandler = (notificationPayload: CheckoutPaymentNotificationType) => {
    setNotifications((prevNotifications) => ({ ...prevNotifications, ...notificationPayload }));
    fetchCreditCards();
  };

  const renderARPaymentMethod = () => {
    return <ARPayment updatePaymentMethod={updatePaymentMethodHandler} arError={notifications.arError} />;
  };

  const renderCCPaymentMethod = () => {
    return (
      fetchedCreditCards && (
        <CCPayment
          creditCards={creditCardsData}
          updatePaymentMethod={updatePaymentMethodHandler}
          notifications={notifications}
          notificationHandler={notificationHandler}
        />
      )
    );
  };

  const renderPaymentMethods = () => {
    if (businessUnitFetched) {
      if (showCreditCardsAndAR) {
        return (
          <>
            <div className={styles.subtext} data-testid="payment-methods__subtext_test-id">
              {formatMessage({ id: 'payment.subtext', defaultMessage: 'Select a payment method' })}.
            </div>
            <AccordionStatelessV2
              id="payment-methods-accordion"
              expandedAccordionPanels={expandedAccordionPanels}
              onClickAccordionItem={accordionItemClickHandler}
              className={styles.accordion}
              data-testid="payment-methods__accordion_test-id"
            >
              <AccordionItemV2
                headingClassName={styles.accordionHeading}
                panelContentClassName={styles.accordionPanel}
                heading={formatMessage({ id: 'pay.ar.heading', defaultMessage: 'Pay with Accounts Receivable' })}
              >
                {renderARPaymentMethod()}
              </AccordionItemV2>
              <AccordionItemV2
                headingClassName={styles.accordionHeading}
                panelContentClassName={styles.accordionPanel}
                heading={formatMessage({
                  id: 'pay.with.cc',
                  defaultMessage: 'Pay with Credit Card',
                })}
              >
                {renderCCPaymentMethod()}
              </AccordionItemV2>
            </AccordionStatelessV2>
          </>
        );
      }

      if (showAccountsReceivable) {
        trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, CHECKOUT.PAYMENT_METHOD(PaymentType.AR));
        return renderARPaymentMethod();
      }

      if (showCreditCards) {
        trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, CHECKOUT.PAYMENT_METHOD(PaymentType.creditCard));
        return renderCCPaymentMethod();
      }

      return (
        <CheckoutNotification
          show={noPaymentOptionAvailable}
          message={
            <>
              {formatMessage({
                id: 'no.payment.method.error',
                defaultMessage:
                  'We were unable to load your payment options. Please contact your Account Manager directly, or email',
              })}
              {accountManagerEmail}
            </>
          }
        />
      );
    }
  };

  return (
    <div className={styles.container}>
      <Heading tag="h2" className={styles.heading} data-testid="payment-methods__heading_test-id">
        {formatMessage({ id: 'paymentMethod', defaultMessage: 'Payment method' })}
      </Heading>
      <div
        className={cx(styles.contentContainer, {
          [styles.loading]: isFetchingCreditCards || isUpdatingPaymentMethod,
        })}
      >
        {(isFetchingCreditCards || isUpdatingPaymentMethod) && <OverlayLoader color="red" name="payment-methods" />}
        {renderPaymentMethods()}
      </div>
    </div>
  );
};

export default PaymentMethods;
