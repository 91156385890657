import React, { FC } from 'react';
import NextLink from 'next/link';
import { Icon, yogoRedIcon } from '@lululemon/ecom-pattern-library';

export interface Props {
  logoLink: string;
  onClick?: () => void;
  size?: number;
}

const SiteLogo: FC<Props> = ({ logoLink, onClick, size = 34 }) => {
  return (
    <NextLink href={logoLink} onClick={onClick}>
      <a>
        <Icon height={size} width={size} content={yogoRedIcon} />
        <span className="lll-hidden-visually">lululemon athletica</span>
      </a>
    </NextLink>
  );
};

export default SiteLogo;
