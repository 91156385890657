import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';

export const HEADER = {
  LULU_LOGO_CLICKED: {
    component: {
      id: 'lulu_logo',
      type: COMPONENT_TYPE.LINK,
      text: `Lulu logo clicked`,
    },
  },
  PRIMARY_NAV_ITEM_CLICKED: (text: string | undefined, url: string | undefined) => {
    return {
      component: {
        id: 'header_primary_nav_item',
        type: COMPONENT_TYPE.LINK,
        text: `Primary nav item clicked`,
      },
      attributes: {
        details: { data: { url, text } },
      },
    };
  },

  MAIN_DETAILS_SUBCATEGORY_LIST_CLICKED: (text: string | undefined, url: string | undefined) => {
    return {
      component: {
        id: 'main_details_subcategory_list',
        type: COMPONENT_TYPE.LINK,
        text: 'Main details subcategory list clicked',
      },
      attributes: {
        details: { data: { url, text } },
      },
    };
  },

  MAIN_DETAILS_CATEGORY_LIST_CLICKED: (text: string, url: string) => {
    return {
      component: {
        id: 'main_details_category_list',
        type: COMPONENT_TYPE.LINK,
        text: 'Main details category list clicked',
      },
      attributes: {
        details: { data: { url, text } },
      },
    };
  },

  SHOPPING_BAG_ICON_CLICKED: () => {
    return {
      component: {
        id: 'shopping_bag_icon',
        type: COMPONENT_TYPE.BUTTON,
        text: `Shopping bag icon click`,
      },
    };
  },
  SEARCH_PRODUCT: (searchValue: string) => {
    return {
      component: {
        id: 'search_product',
        type: COMPONENT_TYPE.SEARCH,
        text: 'Search product clicked',
      },
      attributes: {
        details: { data: { searchValue } },
      },
    };
  },
  SEARCH_INTERACTION_INITIATE: () => {
    return {
      component: {
        id: 'search_interaction_initiate',
        type: COMPONENT_TYPE.BUTTON,
        text: `Search interaction initiate`,
      },
    };
  },
};
