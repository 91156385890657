import { useMemo } from 'react';
import { Category } from 'shared/types/product';
import {
  NavItem,
  HeaderProps,
  Level1Item,
  Level2Item,
  Level3Item,
  Level1NavItem,
  Level3NavItem,
  FeatureProps,
} from 'shared/types/nav';
import { useNavContext } from 'context/nav';
import {
  ACCESSORIES,
  FEATURES,
  BAGS,
  WOMEN,
  MEN,
  ROOT_CATEGORY_SLUG,
  MEN_SOCKS,
  WOMEN_SOCKS,
  MEN_PANTS,
  MEN_SHIRTS,
  WOMEN_PANTS,
  WOMEN_SHIRTS,
} from 'helpers/constants/nav';

const getDisplaySubcategories = (level1Data: Level1NavItem, women: Category | undefined, men: Category | undefined) => {
  const displaySubcategories: Level3NavItem[] = [];
  let displayl2SubcategoryName = '';
  let displayl2SubcategorySlug = '';
  const catTemp = level1Data?.level2CategoryLinksSection.find((elem: Level2Item) => {
    return elem.l2CategoryLinkSlug === level1Data.l1MainCategorySlug;
  });
  if (catTemp) {
    const level3Subcategories = catTemp.level3SubcategoryLinksSection;
    displayl2SubcategoryName = catTemp?.l2CategoryLinkDisplayName;
    displayl2SubcategorySlug = catTemp?.l2CategoryLinkSlug;

    const isMenOrWomen = [MEN, WOMEN].includes(level1Data.l1MainCategorySlug);
    const { l1MainCategorySlug } = level1Data;
    const shirts = l1MainCategorySlug === MEN ? MEN_SHIRTS : l1MainCategorySlug === WOMEN ? WOMEN_SHIRTS : '';
    const pants = l1MainCategorySlug === MEN ? MEN_PANTS : l1MainCategorySlug === WOMEN ? WOMEN_PANTS : '';

    let level4ShirtsCategoryInMC: Category | undefined, level4PantsCategoryInMC: Category | undefined;
    if (isMenOrWomen) {
      level4ShirtsCategoryInMC = level1Data?.subCategories?.find((elem2) => elem2.slug === shirts);
      level4PantsCategoryInMC = level1Data?.subCategories?.find((elem2) => elem2.slug === pants);
    }

    level3Subcategories?.forEach((elem: Level3Item) => {
      const slug = elem?.l3SubcategoryLinkSlug;

      const level3SubcategoryInMC = level1Data?.subCategories?.find((elem2) => elem2.slug === slug);

      if (level3SubcategoryInMC) {
        displaySubcategories.push({ ...elem, ...level3SubcategoryInMC });
      } else if (level1Data.l1MainCategorySlug === ACCESSORIES && slug === MEN_SOCKS) {
        // For Accessories some level 3 categories are not actually in accessories; those are taken from men and women. eg. men-socks and women-socks.
        const level3SubcategoryInMenInMC = men?.subCategories?.find((elem2) => elem2.slug === slug);
        level3SubcategoryInMenInMC && displaySubcategories.push({ ...elem, ...level3SubcategoryInMenInMC });
      } else if (level1Data.l1MainCategorySlug === ACCESSORIES && slug === WOMEN_SOCKS) {
        const level3SubcategoryInWomenInMC = women?.subCategories?.find((elem2) => elem2.slug === slug);
        level3SubcategoryInWomenInMC && displaySubcategories.push({ ...elem, ...level3SubcategoryInWomenInMC });
      } else if (isMenOrWomen) {
        const shirtSubcategory = level4ShirtsCategoryInMC?.subCategories?.find((elem2) => elem2.slug === slug);
        if (shirtSubcategory) {
          displaySubcategories.push({ ...elem, ...shirtSubcategory });
        } else {
          const pantSubcategory = level4PantsCategoryInMC?.subCategories?.find((elem2) => elem2.slug === slug);
          if (pantSubcategory) {
            displaySubcategories.push({ ...elem, ...pantSubcategory });
          }
        }
      }
    });
  }

  return { displaySubcategories, displayl2SubcategoryName, displayl2SubcategorySlug };
};

const getDisplayAccessoriesOrBags = (level1Data: Level1NavItem, accessories: Category | undefined, gender: string) => {
  const displayAccessoriesOrBags: Level3NavItem[] = [];
  let column2ndName = '';
  let column2ndCategoryPath = '';

  const accessoriesOrBagsTemp = level1Data?.level2CategoryLinksSection.find((elem) => {
    return (
      (level1Data.l1MainCategorySlug !== ACCESSORIES && elem.l2CategoryLinkSlug === ACCESSORIES) ||
      (level1Data.l1MainCategorySlug === ACCESSORIES && elem.l2CategoryLinkSlug === BAGS)
    );
  });

  if (accessoriesOrBagsTemp) {
    column2ndName = accessoriesOrBagsTemp?.l2CategoryLinkDisplayName;
    column2ndCategoryPath = accessoriesOrBagsTemp?.l2CategoryLinkSlug;
    const level3Subcategories = accessoriesOrBagsTemp.level3SubcategoryLinksSection;
    level3Subcategories?.forEach((elem: Level3Item) => {
      const slug = elem?.l3SubcategoryLinkSlug;
      const level3AccessoryInMC = accessories?.subCategories?.find((elem2) => elem2.slug === slug);
      if (level3AccessoryInMC) {
        displayAccessoriesOrBags.push({ ...elem, ...level3AccessoryInMC, gender });
      }
    });
  }

  return { displayAccessoriesOrBags, column2ndName, column2ndCategoryPath };
};

const getFeatureIndex = (level1Data: Level1NavItem) => {
  const { level2CategoryLinksSection } = level1Data;
  const featureIndex = level2CategoryLinksSection.findIndex(
    (level1Data) => level1Data?.l2CategoryLinkSlug === FEATURES,
  );
  return featureIndex;
};

const getOtherVariables = (level1Data: Level1NavItem, featureLinks: Level3NavItem[], featureIndex: number) => {
  const { level2CategoryLinksSection } = level1Data;
  const hasFeatures = !!featureLinks.length;
  let level2Length = level2CategoryLinksSection.length;
  const isFeatureAddedInFrontastic = featureIndex !== -1;

  let totalMainColumnCount = level2Length > 3 ? 3 : level2Length;
  if (isFeatureAddedInFrontastic && !hasFeatures) {
    totalMainColumnCount -= 1;
  }

  let hasAccessoriesOrBags = false;
  if (isFeatureAddedInFrontastic) {
    if (level2Length > 2) {
      hasAccessoriesOrBags = true;
    }
  } else if (level2Length > 1) {
    hasAccessoriesOrBags = true;
  }

  return { totalMainColumnCount, hasFeatures, hasAccessoriesOrBags };
};

const getFeatureLinks = ({ level1Data, categories, featureIndex, gender }: FeatureProps) => {
  const { level2CategoryLinksSection } = level1Data;
  let featureLinks: Level3NavItem[] = [];

  if (featureIndex === -1) return [];

  const links = level2CategoryLinksSection[featureIndex];
  const level3Subcategories = links?.level3SubcategoryLinksSection;
  if (!level3Subcategories.length) return [];

  const features = categories?.find((category: Category) => category?.slug === FEATURES);

  level3Subcategories?.forEach((elem: Level3Item) => {
    const slug = elem?.l3SubcategoryLinkSlug;
    const level3FeatureInMC = features?.subCategories?.find((elem2: Category) => elem2.slug === slug);
    if (level3FeatureInMC) {
      featureLinks.push({ ...elem, ...level3FeatureInMC, gender });
    }
  });
  return featureLinks;
};

const useNavData = ({ categories, studioData }: HeaderProps) => {
  const { setMenuLength } = useNavContext();

  const navData = useMemo(() => {
    try {
      const { level1MainCategorySection } = studioData;
      // Below is using the root category slug. We only want to show categories under root category.
      const displayCategory = categories?.find((category) => category?.slug === ROOT_CATEGORY_SLUG);
      const subCategories: Category[] = displayCategory?.subCategories || [];
      subCategories && setMenuLength(subCategories.length);

      let level1NavItem: Level1NavItem[] = [];
      level1MainCategorySection?.forEach((level1Data: Level1Item) => {
        const item = subCategories?.find((elem2) => {
          if (level1Data?.l1MainCategorySlug === elem2?.slug) return elem2;
        });

        if (item) level1NavItem.push({ ...level1Data, ...item });
        else level1NavItem.push(level1Data);
      });

      const displayNavItems: NavItem[] = [];
      const accessories = categories?.find((category) => category?.slug === ACCESSORIES);
      const women = categories?.find((category) => category?.slug === WOMEN);
      const men = categories?.find((category) => category?.slug === MEN);

      level1NavItem.forEach((level1Data) => {
        let gender = '';
        if (level1Data?.l1MainCategorySlug === WOMEN) gender = WOMEN;
        if (level1Data?.l1MainCategorySlug === MEN) gender = MEN;

        const { displaySubcategories, displayl2SubcategoryName, displayl2SubcategorySlug } = getDisplaySubcategories(
          level1Data,
          women,
          men,
        );
        const { displayAccessoriesOrBags, column2ndName, column2ndCategoryPath } = getDisplayAccessoriesOrBags(
          level1Data,
          accessories,
          gender,
        );

        const featureIndex = getFeatureIndex(level1Data);
        const featureLinks = getFeatureLinks({ level1Data, categories, featureIndex, gender });
        const { totalMainColumnCount, hasFeatures, hasAccessoriesOrBags } = getOtherVariables(
          level1Data,
          featureLinks,
          featureIndex,
        );

        displayNavItems.push({
          ...level1Data,
          displaySubcategories,
          displayAccessoriesOrBags,
          column2ndName,
          column2ndCategoryPath,
          displayl2SubcategoryName,
          displayl2SubcategorySlug,
          featureLinks,
          totalMainColumnCount,
          hasFeatures,
          hasAccessoriesOrBags,
          gender,
        });
      });

      return displayNavItems;
    } catch (e) {
      return [];
    }
  }, [categories, studioData]);

  return navData;
};

export default useNavData;
