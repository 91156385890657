import React, { useEffect, useState, useCallback } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Popover } from '@headlessui/react';
import { accountIcon, LinkGenerator, Icon } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import { useNavContext } from 'context/nav';
import { useFormat } from 'helpers/hooks/useFormat';
import useScrollBlock from 'helpers/hooks/useScrollBlock';
import { createRefMap } from 'helpers/utils/nav-ref-map';
import AccountDropdown from 'ui/account/components/account-dropdown';
import { useAccount } from 'frontastic';
import styles from './account-button.module.scss';

const AccountButton = () => {
  const router = useRouter();
  const { getRef, setRef } = createRefMap();
  const { loggedIn } = useAccount();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const title = formatAccountMessage({ id: 'account', defaultMessage: 'Account' });
  const { blockScroll } = useScrollBlock();
  const {
    isAccountButtonFocused,
    setAccountButtonFocused,
    isAccountMenuVisible,
    setAccountMenuVisible,
    isAccountMenuListFocused,
    setAccountMenuListFocused,
    handleCloseDetailedNav,
  } = useNavContext();

  useEffect(() => {
    if (!isAccountButtonFocused && isAccountMenuVisible) {
      reOpenPopup();
    }
  }, [isAccountButtonFocused]);

  useEffect(() => {
    blockScroll(isAccountMenuVisible);
  }, [blockScroll, isAccountMenuVisible]);

  const handleKeydown = useCallback(
    (evt: any) => {
      switch (evt.key) {
        case 'ArrowDown': {
          evt.preventDefault();
          if (isAccountMenuVisible) {
            setAccountMenuListFocused(true);
            setAccountButtonFocused(false);
          }
          break;
        }
        case 'Tab': {
          setAccountMenuVisible(false);
          setAccountButtonFocused(false);
          break;
        }
        case 'Enter': {
          evt.preventDefault();
          setAccountMenuVisible(false);
          router.push('./account');
          break;
        }
        case 'Escape': {
          evt.preventDefault();
          getRef(`account-title`).current?.blur();
          break;
        }
        default:
          break;
      }
    },
    [isAccountMenuVisible, getRef],
  );

  const closePopup = () => {
    setAccountMenuVisible(false);
    setAccountButtonFocused(false);
    setAccountMenuListFocused(false);
  };

  const closeOnMouseOver = () => {
    if (!isAccountButtonFocused) closePopup();
  };

  const onFocusPopup = () => {
    setAccountMenuVisible(true);
    setAccountButtonFocused(true);
  };

  const reOpenPopup = () => {
    if (typeof getRef(`account-title`) !== 'undefined') {
      getRef(`account-title`).current.focus();
      getRef(`account-title`).current.blur();
    }
    setAccountMenuVisible(true);
  };

  const onMouseOverOnPopup = () => {
    handleCloseDetailedNav();

    if (isAccountMenuListFocused) {
      setAccountMenuListFocused(false);
      reOpenPopup();
    } else {
      setAccountMenuVisible(true);
      setAccountButtonFocused(false);
    }
  };

  return (
    <div data-testid="account-button_test-id">
      <div
        data-testid="account-button__user-interaction_test-id"
        className={cs({
          [styles.bodyMask]: isAccountMenuVisible,
        })}
        onMouseOver={closeOnMouseOver}
        role="presentation"
        aria-hidden="true"
      />
      <Popover
        as="div"
        className={styles.popup}
        onFocus={onFocusPopup}
        onBlur={closePopup}
        onMouseOver={onMouseOverOnPopup}
      >
        <NextLink href={'/account'}>
          <LinkGenerator
            className={styles.iconWrapper}
            onKeyDown={handleKeydown}
            tabIndex={0}
            ref={setRef('account-title')}
          >
            <Icon content={accountIcon} />
            <span className={cs('body-3', styles.underline)}>{title}</span>
          </LinkGenerator>
        </NextLink>
        {loggedIn && isAccountMenuVisible && (
          <Popover.Panel static className={styles.panel}>
            <span className={styles.arrowUp}></span>
            <AccountDropdown closePopup={closePopup} />
          </Popover.Panel>
        )}
      </Popover>
    </div>
  );
};

export default AccountButton;
