import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Icon, bagIcon } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import { useFormat } from 'helpers/hooks/useFormat';
import { HEADER } from 'helpers/utils/googleAnalyticsEvents';
import { useAnalytics } from 'hooks/useAnalytics';
import styles from './quick-view-bag.module.scss';
type QuickViewBagProps = {
  cartCount: number;
  hideBeacon?: boolean;
};

const QuickViewBag = ({ hideBeacon = false, cartCount }: QuickViewBagProps) => {
  // TODO later cartCount will get value from CartContext instead of prop
  // For test case support now added cartCount as a prop, later when getting data from context, then we will update test cases too.
  const [isBeaconVisible, setIsBeaconVisible] = useState(false);
  const ALERTTIME = 7000;
  const { formatMessage } = useFormat({ name: 'cart' });
  const router = useRouter();
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();
  useEffect(() => {
    if (hideBeacon) return;

    // For cartCount >= 1 only we will show beacon
    if ([0, null, undefined].includes(cartCount)) {
      setIsBeaconVisible(false);
      return;
    }
    const timer = setTimeout(() => {
      setIsBeaconVisible(true);
    }, ALERTTIME);
    return () => clearTimeout(timer);
  }, [cartCount]);

  const isCartCountValid = cartCount !== null && cartCount >= 0;

  const loadingMessage = formatMessage({ id: 'loading.items', defaultMessage: 'Loading items' });
  const cartCountMessage = isCartCountValid
    ? formatMessage({
        id: 'items.count',
        defaultMessage: `${cartCount} items`,
        values: { count: cartCount },
      })
    : loadingMessage;

  return (
    // we have used button tag below, so that enter key press can work on that.
    <button
      className={styles.wrapper}
      title={cartCountMessage}
      onClick={() => {
        router.push('/cart');
        trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.SHOPPING_BAG_ICON_CLICKED);
      }}
    >
      <div
        data-testid="quick-view-bag_test-id"
        className={cs(styles.icon, {
          [styles.bagIndicator]: isBeaconVisible && !hideBeacon,
        })}
      >
        <Icon content={bagIcon} />
      </div>
      {/* We will show 0 beside of bag icon, until we do not get data from context */}
      <span className={styles.count}>{cartCount?.toLocaleString() || 0}</span>
    </button>
  );
};

export default QuickViewBag;
