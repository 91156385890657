import { useCallback } from 'react';
import { SDKResponse } from '@commercetools/frontend-sdk/lib';
import { CardInfoProps } from 'shared/types/homePage';
import { Category } from 'shared/types/product';
import { Result } from 'shared/types/product/Result';
import { ProductQuery } from 'shared/types/query/ProductQuery';
import useSWR from 'swr';
import { sdk } from 'sdk';

interface UseProductReturn {
  categories: Category[];
  query: (productQuery: ProductQuery) => Promise<SDKResponse<Result>>;
  getProductForQuickLink: (slugs: string[]) => Promise<any>;
  getProductsForWhatsNewCard: (cardInfo: CardInfoProps[]) => Promise<any>;
  getCategoryPromotionCarouselProducts: (slugs: string, limit: number) => Promise<any>;
}

export const useProduct = (): UseProductReturn => {
  const extensions = sdk.composableCommerce;

  const categoriesResults = useSWR('/action/product/queryCategories', () =>
    extensions.product.queryCategories({ limit: 500 }),
  );

  const categories = (categoriesResults.data?.isError ? [] : (categoriesResults.data?.data?.items as Category[])) ?? [];

  const query = useCallback(async (productQuery: ProductQuery) => {
    /* To Do: Use SDK instead of current workaround */

    const extensions = sdk.composableCommerce;

    const res = await extensions.product.query({ limit: productQuery.limit, query: productQuery.query });

    return res;
    // return { data: res, isError: false } as SDKResponse<any>;

    // const params = new URLSearchParams();

    // if (productQuery.query) params.set('query', productQuery.query);
    // if (productQuery.limit) params.set('limit', productQuery.limit);

    // const res = await fetchApiHub(`/action/product/query?${params.toString()}`, SDK.locale);

    // return { data: res, isError: false } as SDKResponse<Result>;
  }, []);

  const getProductForQuickLink = useCallback(async (categoryIds: string[]) => {
    const response = await sdk.callAction({
      actionName: 'product/getProductForQuickLink',
      payload: categoryIds,
    });
    return response.isError ? {} : response.data;
  }, []);

  const getProductsForWhatsNewCard = useCallback(async (cardInfo: CardInfoProps[]) => {
    const response = await sdk.callAction({
      actionName: 'product/getProductsForWhatsNewCard',
      payload: cardInfo,
    });
    return response.isError ? {} : response.data;
  }, []);

  const getCategoryPromotionCarouselProducts = useCallback(async (categoryId: string, limit: number) => {
    const response = await sdk.callAction({
      actionName: 'product/getCategoryPromotionCarouselProducts',
      query: { categoryId, limit },
    });
    return response.isError ? {} : response.data;
  }, []);

  return {
    categories,
    query,
    getProductForQuickLink,
    getProductsForWhatsNewCard,
    getCategoryPromotionCarouselProducts,
  };
};
