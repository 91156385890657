import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';

export const CUSTOMER_SUPPORT = {
  FAQ_ACCORDION_CLICKED: (title: string | undefined) => {
    return {
      component: {
        id: 'faq_accordion',
        type: COMPONENT_TYPE.BUTTON,
        text: `FAQ accordion clicked`,
      },
      attributes: {
        details: { data: title },
      },
    };
  },
  CONTACT_US_EMAIL_LINK_CLICKED: {
    component: {
      id: 'contact_us_account_manager_email_link',
      type: COMPONENT_TYPE.LINK,
      text: `Contact us account manager email link clicked`,
    },
  },
};
