import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';

export const BREADCRUMB = (link: string | undefined, text: string | undefined) => {
  return {
    component: {
      id: 'breadcrumb_link',
      type: COMPONENT_TYPE.LINK,
      text: `Breadcrumb clicked`,
    },
    attributes: {
      details: { data: { link, text } },
    },
  };
};
