import React, { FC, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Field, Button, Link, Notification, Price } from '@lululemon/ecom-pattern-library';
import cx from 'classnames';
import { NgcErrorType } from 'shared/types/error';
import { useCart } from 'context';
import { useFormat } from 'helpers/hooks/useFormat';
import { parseNGCError } from 'helpers/ngc';
import { CanadaState, Country } from 'helpers/utils/countryHelper';
import { CHECKOUT } from 'helpers/utils/googleAnalyticsEvents';
import { NgcErrorCodes } from 'helpers/utils/ngcResponseUtil';
import { useAnalytics } from 'hooks/useAnalytics';
import { useBusinessUnit } from 'frontastic/hooks';
import styles from './paymentSummary.module.scss';
import { CheckoutStep } from '../..';
import { renderShippingPrice } from '../../utils';

interface PaymentSummaryProps {
  activeStep: CheckoutStep;
}

export enum PromoCodeActions {
  'ADD' = 'ADD',
  'REMOVE' = 'REMOVE',
}

export type PaymentItem = {
  key: string;
  defaultLabel: string;
  value: string | React.JSX.Element;
};

const PaymentSummary: FC<PaymentSummaryProps> = ({ activeStep }) => {
  const [promoCodeInputValue, setPromoCodeInputValue] = useState<string>('');
  const [promoError, setPromoError] = useState({ message: '', type: '' });
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  const { businessUnit } = useBusinessUnit();

  const { formatMessage } = useFormat({ name: 'checkout' });

  const { cart, addPromoCode, removePromoCode } = useCart();
  const router = useRouter();

  const inputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCodeInputValue(e.target.value);
    setPromoError({ message: '', type: '' });
  };

  const setPromoCodeError = (error: NgcErrorType, type: PromoCodeActions) => {
    let errorMessage = '';
    if (type === PromoCodeActions.ADD) {
      if (
        error?.code === NgcErrorCodes.MAX_ALLOWED_DISCOUNT_CODE_ERROR ||
        error?.code === NgcErrorCodes.DISCOUNT_CODE_MAX_APPLICATION_REACHED
      ) {
        errorMessage = formatMessage({
          id: 'promo.unredeemable',
          defaultMessage: 'This promo code can no longer be redeemed.',
        });
      } else if (error?.code === NgcErrorCodes.DISCOUNT_CODE_NOT_APPLICABLE) {
        errorMessage = formatMessage({
          id: 'promo.expired',
          defaultMessage: 'This promo code has expired.',
        });
      } else if (error?.code === NgcErrorCodes.DISCOUNT_CODE_TIME_RANGE_NOT_APPLICABLE) {
        errorMessage = formatMessage({
          id: 'promo.not.active.error',
          defaultMessage: 'Promo code is inactive.',
        });
      } else if (error?.code === NgcErrorCodes.CART_ALREADY_ORDERED) {
        router.push('/something?isCartAlreadyOrdered=true');
      } else {
        errorMessage = formatMessage({
          id: 'promo.unrecognized.error',
          defaultMessage:
            'Promo code not recognized. The codes are case sensitive, so make sure to type it exactly how it appears.',
        });
      }
    }

    if (type === PromoCodeActions.REMOVE) {
      errorMessage = formatMessage({
        id: 'remove.promo.error',
        defaultMessage: 'Unable to remove promo code. Please try again.',
      });
    }

    setPromoError({ message: errorMessage, type: type });
  };

  const addPromoCodeHandler = async () => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, CHECKOUT.PROMO_CODE_ACTION(promoCodeInputValue, PromoCodeActions.ADD));
    try {
      await addPromoCode(promoCodeInputValue);
      setPromoError({ message: '', type: '' });
    } catch (error) {
      const parsedError = parseNGCError(error);
      setPromoCodeError(parsedError, PromoCodeActions.ADD);
    }
  };

  const removePromoCodeHandler = async () => {
    trackEvent(
      EVENT_CATEGORY.COMPONENT_EVENT,
      CHECKOUT.PROMO_CODE_ACTION(promoCodeInputValue, PromoCodeActions.REMOVE),
    );
    const promotionId: string = cart?.promotions?.id || '';
    try {
      await removePromoCode(promotionId);
      setPromoCodeInputValue('');
      setPromoError({ message: '', type: '' });
    } catch (error) {
      const parsedError = parseNGCError(error);
      setPromoCodeError(parsedError, PromoCodeActions.REMOVE);
    }
  };

  const paymentItems = useMemo(() => {
    const displayTaxContent = (taxPrice: number | undefined) => {
      if (taxPrice === undefined) {
        return '-';
      }
      if (CheckoutStep.SHIPPING === activeStep) {
        return formatMessage({ id: 'calculated.at.next.step', defaultMessage: 'Calculated at next step' });
      } else {
        return <Price prices={taxPrice} showDecimals />;
      }
    };

    const country = businessUnit?.custom?.fields?.partner_country;
    const state = cart?.shippingAddress?.state;
    const lineItemSubtotal = cart?.cartTotal?.lineItemTotal?.subtotal;
    const shippingCost = cart?.cartTotal?.shippingTotal?.subtotal;
    const totalValue = CheckoutStep.SHIPPING === activeStep ? cart?.cartTotal?.subtotal : cart?.cartTotal?.total;

    const paymentData: PaymentItem[] = [
      {
        key: 'subtotal',
        defaultLabel: 'Subtotal',
        value: <Price prices={lineItemSubtotal} showDecimals />,
      },
      {
        key: 'shipping',
        defaultLabel: 'Shipping',
        value: renderShippingPrice(shippingCost, formatMessage({ id: 'freeShipping', defaultMessage: 'Free' })),
      },
    ];

    const taxData = cart?.cartTotal?.tax;

    if (country === Country.CA) {
      paymentData.push({
        key: 'gst',
        defaultLabel: 'GST/HST',
        value: displayTaxContent(taxData?.['gst/hst']),
      });
      if (state === CanadaState.BC || state === CanadaState.SK) {
        paymentData.push({
          key: 'pst',
          defaultLabel: 'PST',
          value: displayTaxContent(taxData?.['pst/rst']),
        });
      } else if (state === CanadaState.MB) {
        paymentData.push({
          key: 'rst',
          defaultLabel: 'RST',
          value: displayTaxContent(taxData?.['pst/rst']),
        });
      } else if (state === CanadaState.QC) {
        paymentData.push({
          key: 'qst',
          defaultLabel: 'QST',
          value: displayTaxContent(taxData?.qst),
        });
      }
    } else if (country === Country.US) {
      paymentData.push({
        key: 'stateTax',
        defaultLabel: 'State tax',
        value: displayTaxContent(taxData?.total),
      });
    }

    paymentData.push({
      key: 'orderTotal',
      defaultLabel: 'Order total',
      value: totalValue ? <Price prices={totalValue} showDecimals /> : '-',
    });

    return paymentData;
  }, [businessUnit, cart, activeStep]);

  const renderPaymentSummary = () => {
    return (
      <div className={styles.paymentItemsContainer}>
        {paymentItems.map((paymentItem: PaymentItem) => (
          <div
            key={paymentItem.key}
            className={cx(styles.paymentItem, {
              [styles.orderTotal]: paymentItem.key === 'orderTotal',
            })}
          >
            <div data-testid={`payment-summary__${paymentItem.key}-label_test-id`}>
              {formatMessage({
                id: paymentItem.key,
                defaultMessage: paymentItem.defaultLabel,
              })}
            </div>
            <div data-testid={`payment-summary__${paymentItem.key}-value_test-id`}>{paymentItem.value}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderPromoCodeForm = () => {
    return (
      <div className={styles.promoContainer} data-testid="payment-summary__form-container_test-id">
        <div className={styles.inputContainer}>
          <Field
            id="payment-summary__promocode-text"
            name={formatMessage({
              id: 'promo.code',
              defaultMessage: 'Promo code',
            })}
            data-testid="payment-summary__promocode-text_test-id"
            onChange={inputOnChangeHandler}
            className={styles.field}
            value={promoCodeInputValue}
            hint={Boolean(promoError.message) ? promoError.message : undefined}
            status={Boolean(promoError.message) ? 'error' : undefined}
          >
            {formatMessage({
              id: 'promo.code',
              defaultMessage: 'Promo code',
            })}
          </Field>
        </div>
        <Button
          kind="secondary"
          onClick={addPromoCodeHandler}
          data-testid="payment-summary__apply-button_test-id"
          className={styles.applyButton}
        >
          {formatMessage({
            id: 'apply',
            defaultMessage: 'Apply',
          })}
        </Button>
      </div>
    );
  };

  const renderAppliedPromoCode = () => {
    return (
      <>
        <div className={styles.promotionRemoved} data-testid="payment-summary__applied-promocode-text_test-id">
          <div className="flex flex-col">
            <div className={styles.appliedPromoCode}>
              {formatMessage({
                id: 'promo.code.applied',
                defaultMessage: 'Promo code applied',
              })}
            </div>
            <span
              className={styles.promoCodeValue}
              id="required-text-input"
              data-testid="payment-summary__applied-promocode_test-id"
            >
              {cart?.promotions?.code}
            </span>
          </div>
          {activeStep === CheckoutStep.SHIPPING && (
            <Link
              tag="button"
              className="lll-text-body-2"
              onClick={removePromoCodeHandler}
              data-testid="payment-summary__remove-link_test-id"
              aria-label={formatMessage({ id: 'remove.promo.code', defaultMessage: 'Remove Promo Code' })}
            >
              {formatMessage({
                id: 'remove',
                defaultMessage: 'Remove',
              })}
            </Link>
          )}
        </div>
        {promoError.message && promoError.type === PromoCodeActions.REMOVE && (
          <Notification type="error" visible={Boolean(promoError.message)} className={styles.notification}>
            {promoError.message}
          </Notification>
        )}
      </>
    );
  };

  return (
    <div className={styles.paymentSummaryContainer} data-testid="payment-summary__container_test-id">
      {renderPaymentSummary()}
      {activeStep === CheckoutStep.SHIPPING && (
        <div className={styles.promoCodeContainer}>
          {!cart?.promotions?.code ? renderPromoCodeForm() : renderAppliedPromoCode()}
        </div>
      )}
      {activeStep !== CheckoutStep.SHIPPING && cart?.promotions?.code && renderAppliedPromoCode()}
    </div>
  );
};

export default PaymentSummary;
