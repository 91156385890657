import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';

export const CART = {
  ADD_TO_CART_CLICKED: (data: any) => {
    return {
      component: {
        id: 'add_to_cart',
        type: COMPONENT_TYPE.BUTTON,
        text: `Add to cart clicked`,
      },
      attributes: {
        details: { data },
      },
    };
  },

  SHOPPING_CART_DETAILS: (data: any) => {
    return {
      component: {
        id: 'cart_details',
        type: COMPONENT_TYPE.CART_DETAILS,
        text: `Cart details`,
      },
      attributes: {
        details: { data },
      },
    };
  },

  CHECKOUT_CLICKED: (data: any) => {
    return {
      component: {
        id: 'checkout',
        type: COMPONENT_TYPE.BUTTON,
        text: `Checkout button clicked`,
      },
      attributes: {
        details: { data },
      },
    };
  },

  REMOVE_LINE_ITEM_CLICKED: (data: any) => {
    return {
      component: {
        id: 'remove_line_item',
        type: COMPONENT_TYPE.BUTTON,
        text: `Remove line item clicked`,
      },
      attributes: {
        details: { data },
      },
    };
  },

  REMOVE_LINE_ITEM_API_RESPONSE: {
    event: {
      id: 'remove_line_item_api_response',
      attributes: {
        details: 'Remove line item call success response',
      },
    },
  },

  REMOVE_LINE_ITEM_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'remove_line_item_api_error',
        errorMessage: 'Error in calling remove line item  API',
        errorDetails,
      },
    };
  },

  CHECKOUT_API_RESPONSE: {
    event: {
      id: 'checkout_api_response',
      attributes: {
        details: 'Checkout call success response',
      },
    },
  },

  CHECKOUT_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'remove_line_item_api_error',
        errorMessage: 'Error in calling checkout API',
        errorDetails,
      },
    };
  },

  UPDATE_CART_LINE_ITEM_CLICKED: (data: any) => {
    return {
      component: {
        id: 'update_cart_line_item',
        type: COMPONENT_TYPE.BUTTON,
        text: `Update cart line item clicked`,
      },
      attributes: {
        details: { data },
      },
    };
  },

  UPDATE_CART_API_RESPONSE: {
    event: {
      id: 'update_cart_api_response',
      attributes: {
        details: 'Update cart call success response',
      },
    },
  },

  UPDATE_CART_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'update_cart_api_error',
        errorMessage: 'Error in calling update cart API',
        errorDetails,
      },
    };
  },
};
