import { CheckoutStep } from 'ui/checkout';
import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';
import { NgcCart } from 'shared/types/ngcCart';
export const CHECKOUT = {
  ORDER_CONFIRMED: (orderId: string) => {
    return {
      component: {
        id: 'filter_pill_clicked',
        type: COMPONENT_TYPE.BUTTON,
        text: `Order Confirmed`,
      },
      attributes: {
        details: { data: orderId },
      },
    };
  },

  CTA_ACTION: (stepValue: number, orderData: NgcCart | undefined) => {
    let stepTextId = '';
    let stepText = '';
    switch (stepValue) {
      case CheckoutStep.SHIPPING:
        stepTextId = 'checkout-step-payment-cta';
        stepText = 'Checkout Select Payment Method CTA is clicked';
        break;
      case CheckoutStep.PAYMENT:
        stepTextId = 'checkout-step-review-order-cta';
        stepText = 'Checkout Review Order CTA is clicked';
        break;
      case CheckoutStep.REVIEW_SUMMARY:
        stepTextId = 'checkout-step-place-orders-cta';
        stepText = 'Checkout Place Orders CTA is clicked';
        break;
    }
    return {
      component: {
        id: stepTextId,
        type: COMPONENT_TYPE.BUTTON,
        text: stepText,
      },
      attributes: {
        details: { data: { stepInfo: stepTextId, orderInfo: orderData } },
      },
    };
  },
  PROMO_CODE_ACTION: (promo_code: string, action: string) => {
    const text = action === 'APPLIED' ? 'PROMO CODE APPLIED' : 'PROMO CODE REMOVED';

    return {
      component: {
        id: 'promo_code_text',
        type: COMPONENT_TYPE.BUTTON,
        text: text,
      },
      attributes: {
        details: { data: promo_code },
      },
    };
  },
  PAYMENT_METHOD: (payment_method: string) => {
    const text = `Applied Payment Method is ${payment_method}`;
    return {
      component: {
        id: 'payment_method',
        type: COMPONENT_TYPE.BUTTON,
        text: text,
      },
      attributes: {
        details: { data: payment_method },
      },
    };
  },
};
